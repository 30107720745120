export const careerData = [
    {
  
      company:
        "LOLC Technologies",
      position: 'Internship',
      duration : '1 Year'
    },
    {
      company:
        "Freelancer",
        position: 'Software Engineer',
        duration : '2023 - Present'
    }
  ];
  