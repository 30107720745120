export const projectsData = [
  {
  name: 'Boat Safari Website',
technologies: 'HTML, CSS, JavaScript, Notepad',
year: '2020',
// githubUrl: "",
//     webUrl: ""
},
{
name: 'Employee Management System',
technologies: 'MVC-Architecture, Java, MySQL, Eclipse',
year: '2020',
// githubUrl: "",
//     webUrl: ""
},
{
name: 'Hotel Management System',
technologies: 'MERN Stack, VS Code',
year: '2020',
// githubUrl: "",
//     webUrl: ""
},
];

export const projectsData1 = [
  {
    name: 'Restaurant Management Sysytem',
    technologies: 'Java, Android Studio, SQLite',
    year: '2021',
    // githubUrl: "",
    // webUrl: ""
    },
    {
    name: 'TODO Mobile App',
    technologies: 'Flutter, VS Code, Android Studio',
    year: '2023',
    // githubUrl: "",
    // webUrl: ""
    },
    {
    name: 'Research Website',
    technologies: 'React JS, VS Code',
    year: '2023',
    // githubUrl: "",
    // webUrl: "https://greenag-website.pages.dev/"
    }
];

export const personalprojectsData = [
  {

    name: "VisitLanka - Website",
    technologies: 'MERN Stack',
    year : '2023',
    // githubUrl: "https://github.com/PasinduPerera10/VisitLanka.git",
    webUrl: "https://visitlanka.pages.dev/"
  },
  {
    name: "Healthcare Management System - Web Application",
    technologies: 'Spring boot, React Js, MySql',
    year : '2023',
    // githubUrl: "https://github.com/PasinduPerera10/VisitLanka.git",
    webUrl: "https://healthcare-management-system.pages.dev/"
  }
];
