import React from 'react'
import './Education.css'
import {educationData} from '../../data/educationData'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const Education = () => {

  return (
    <div className='educations-container' id='educations'>
      <div className='educations-heading'>
        My Education
        </div>
      <div className='education-content'>
     {educationData.map((education, i)=>(
     <Card sx={{ maxWidth: 345 }} className="education-slide">
      <CardContent className='education-card-content'>
        <Typography gutterBottom variant="h5" component="div">
          {education.school}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {education.level}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {education.year}
        </Typography>
      </CardContent>
    </Card> 
    ))}
    </div>  
    </div>
    
  )
}

export default Education