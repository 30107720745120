export const educationData = [
    {
  
      school:
        "Ananda Shastralaya - Kotte",
      level: 'Upto Ordinary Level',
      year : '2005 - 2016'
    },
    {
  
      school: 'Ananda College - Colombo 10',
      level: 'Upto Advanced Level',
      year: '2016 - 2019'
    },
    {
  
      school: 'National Institute of Business Management',
      level: 'Computer Science Certificate Course',
      year: '2019 - 2020'
    },
    {
  
      school:'Sri Lanka Institute of Information Technology',
      level: 'Software Engineering - BSc (Hons) in Information Technology',
      year: "2021 - 2024"
    }
  ];
  