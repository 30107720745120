import React, {useState} from 'react'
import './Header.css'
import Bars from '../../assests/bars.png'
import {Link} from "react-scroll"

const Header = () => {
  const mobile = window.innerWidth<=768 ? true: false;
  const [menuOpened, setMenuOpened] = useState(false)
  return (
    <div className='header-container'>
        {menuOpened === false && mobile === true ? (
      <div style={{backgroundColor: "var(--appColor)", padding: "0.5rem", borderRadius: "5px"}} onClick={()=> setMenuOpened(true)}>
      <img src={Bars} alt="" style={{width: "1.5rem", height: "1.5rem", padding:"1rem"}}/>
      </div>
      ) : (
      <ul className='header-list'>
      <li onClick={()=>setMenuOpened(false)}><Link to='home' 
        span={true} 
        style={{cursor: "pointer"}}
        smooth={true}>Home</Link></li>
        <li onClick={()=>setMenuOpened(false)}><Link to='projects' 
        span={true} 
        style={{cursor: "pointer"}}
        smooth={true}>Projects</Link></li>
        <li onClick={()=>setMenuOpened(false)}><Link to='skills' 
        span={true} 
        style={{cursor: "pointer"}}
        smooth={true}>Skills</Link></li>
        <li onClick={()=>setMenuOpened(false)}><Link to='careers' 
        span={true} 
        style={{cursor: "pointer"}}
        smooth={true}>Career</Link></li>
        <li onClick={()=>setMenuOpened(false)}>
          <Link 
        to='educations'
        style={{cursor: "pointer"}}
        span={true} 
        smooth={true}>
          Education
          </Link>
          </li>
          <li>
            <a 
              href="https://pasinduperera10.medium.com/" 
              target="_blank" 
              rel="noopener noreferrer"
              className="blog-link"
            >
              My Blogs
            </a>
          </li>
      </ul>
      )}
    </div>
  )
}

export default Header
