import React, { useEffect, useState } from 'react';
import './Hero.css';
import image1 from '../../assests/image3.png';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import { Link } from 'react-scroll';

const Hero = () => {
  const [typedText, setTypedText] = useState('');
  const [textIndex, setTextIndex] = useState(0);

  useEffect(() => {
    const texts = ["I'm a Software Engineer"];
    const typeText = () => {
      const currentText = texts[textIndex];
      if (typedText === currentText) {
        // Text is already typed, move to the next one
        setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
      } else if (typedText.length < currentText.length) {
        // Type one character at a time
        setTypedText(currentText.slice(0, typedText.length + 1));
      }
    };

    const typeInterval = setInterval(typeText, 100); // Adjust typing speed here

    return () => {
      clearInterval(typeInterval);
    };
  }, [typedText, textIndex]);

  const handleDownloadResume = () => {
    const resumeFileName = 'PasinduPerera_Resume.pdf'; // Specify the correct file name
    const link = document.createElement('a');
    link.href = resumeFileName;
    link.download = resumeFileName;
    link.click();
  };  

  return (
    <div className="shape-overlay">
      <div className="hero-container" id="home">
        <div className="hero-left">
          <motion.span
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.8 }}
            className='stroke-text'>Hello</motion.span> 
          <span>I'm Pasindu Perera</span>
          <span className="stroke-text">{typedText}</span>
          <Link to="contact" span={true} smooth={true}>
            <Button
              className="btn-hireme"
              variant="outlined"
              style={{
                border: '1px solid goldenrod',
                color: 'white',
                marginTop: '2rem',
                borderRadius: '10px',
                marginRight: '1rem', // Add margin to separate buttons
              }}
            >
              Contact Me
            </Button>
          </Link>
          <Button
            className="btn-download-resume"
            variant="outlined"
            style={{
              border: '1px solid goldenrod',
              color: 'white',
              marginTop: '1rem',
              borderRadius: '10px',
              width: "260px"
            }}
            onClick={handleDownloadResume}
          >
            Download My Resume
          </Button>
        </div>
        <div className="hero-right">
          <motion.img
            src={image1}
            initial={{ right: '8rem' }}
            whileInView={{ top: '4rem' }}
            transition={{ type: 'spring', duration: 3 }}
            className="bluecircle1"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;